<template>
  <v-dialog v-model="dialog" persistent fullscreen>
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Dados do Candidato</v-toolbar-title>
      </v-toolbar>

      <v-form ref="form" class="pa-4">
        <v-container>
          <v-row>
            <v-col v-for="(value, label) in formFields" :key="label" cols="12" md="6">
              <v-text-field
                :label="label"
                :value="value"
                outlined
                dense
                readonly
                disabled
              />
            </v-col>
          </v-row>
        </v-container>

        <v-row class="text-center">
          <v-col cols="12" class="text-center my-2">
            <v-tooltip v-if="candidate.curriculo" bottom>
              <template #activator="{ on }">
                <v-btn
                  color="success"
                  :href="candidate.curriculo"
                  target="_blank"
                  outlined
                  elevation="2"
                  class="text-bold"
                  ripple
                  v-on="on"
                >
                  <v-icon left>
                    mdi-download
                  </v-icon>
                  Baixar Currículo
                </v-btn>
              </template>
              <span>Baixe o currículo do candidato </span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import CandidatesApi from '../../../services/candidates.service';

export default {
  name: 'CandidatesForm',
  props: {
    populateWith: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      dialog: true,
      loading: false,
      files: [],
      candidate: this.populateWith || {},
    };
  },
  computed: {
    formFields() {
      const {
        id, name, jobId, numcad, contact = {},
      } = this.candidate;
      return {
        'ID da Vaga': id,
        Nome: name,
        'ID do Cargo': jobId,
        Numcad: numcad,
        'ID de Contato': contact.id,
        Telefone: contact.phone,
        'E-mail': contact.email,
        Usuário: contact.user,
        'Última Atualização': contact.updatedAt ? new Date(contact.updatedAt).toLocaleString() : '',
      };
    },
  },
  methods: {
    close() {
      this.dialog = false;
      this.$emit('close-dialog');
    },
    sendCandidature() {
      if (!this.files.length) {
        this.$toast.error('Por favor, carregue seu currículo antes de enviar.');
        return;
      }
      this.submitCandidature();
    },
    async submitCandidature() {
      this.loading = true;
      try {
        await CandidatesApi.applyToJob(this.candidate, this.files[0]);
        this.$toast.success('Candidatura enviada com sucesso.');
      } catch (error) {
        this.$handleHttpError(error);
      } finally {
        this.close();
        this.loading = false;
      }
    },
    uploadFiles() {
    },
  },
};
</script>
